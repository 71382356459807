import * as types from "../mutations"

export default {
    namespaced: true,

    status: {
        keywords: []
    },

    actions: {
        //fetch all keywords
        fetchAll({ commit }) {
            return axios.get('/keywords').then(res => {
                commit(types.KEYWORDS_STORE, res.data)
                return res.data
            })
        },

        //return the keywords. and if it's empty fetch keywords
        fetchAllOrGet({ commit, state }) {
            return state.keywords || axios.get('/keywords').then(res => {
                commit(types.KEYWORDS_STORE, res.data)
                return res.data
            })
        },
    },

    mutations: {
        //fill the state with keywords
        [types.KEYWORDS_STORE](state, keywords) {
            state.keywords = keywords
        }
    },

    getters: {
        //return one keywords by it's slug
        getBySlug: state => slug => state.keywords.find(e => e.slug === slug),
        getById: state => id => state.keywords.find(e => e.id === id)
    }
}