import * as types from '../mutations';

export default {
    namespaced: true,

    state: {
        teachers: [],
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios
                .get('/custom-services', {
                    params: {
                        type: 2,
                        typeName: 'staff',
                    },
                })
                .then((res) => {
                    commit(types.TEACHERS_STORE, res.data);
                    return res.data;
                });
        },
    },

    mutations: {
        [types.TEACHERS_STORE](state, teachers) {
            state.teachers = teachers;
        },
    },
};
