<template>
	<div class="main-nav">
		<div
			class="top-nav py-2 main-bg white-text"
			v-if="contacts">
			<div class="container">
				<div class="d-flex justify-content-between flex-wrap">
					<div class="d-flex mb-2 mb-sm-0">
						<a
							:href="contacts.address"
							target="_blank"
							class="location ms-3"
							v-if="contacts.address">
							{{ contacts.address }}
						</a>
						<a
							:href="`tel://${contacts.phone}`"
							target="_blank"
							class="phone"
							style="min-width: max-content"
							v-if="contacts.phone">
							<span>
								{{ contacts.phone }}
							</span>
						</a>
					</div>

					<div class="social">
						<a
							:href="contacts.facebook"
							target="_blank"
							class="facebook"
							v-if="contacts.facebook">
						</a>
						<a
							:href="contacts.twitter"
							target="_blank"
							class="twitter"
							v-if="contacts.twitter">
						</a>
						<a
							:href="contacts.instagram"
							target="_blank"
							class="instagram"
							v-if="contacts.instagram">
						</a>
					</div>
				</div>
			</div>
		</div>

		<div
			class="bottom-nav"
			:class="{ pages: $route.name !== 'home' }">
			<div class="container">
				<div
					class="d-flex justify-content-between align-items-center py-1">
					<div class="brand">
						<div class="image">
							<router-link :to="{ name: 'home' }">
								<img
									src="@/assets/Logo.png"
									alt="" />
							</router-link>
						</div>
					</div>
					<nav
						class="nav-links"
						:class="{ show: showNav }">
						<ul class="d-flex nav-list">
							<li class="nav-list__item">
								<router-link :to="{ name: 'home' }">
									الرئيسية
								</router-link>
							</li>
							<li class="nav-list__item">
								<router-link :to="{ name: 'AboutUs' }">
									عن المدرسة
								</router-link>
							</li>
							<li class="nav-list__item">
								<router-link :to="{ name: 'ConductCode' }">
									النظام الداخلي
								</router-link>
							</li>
							<li class="nav-list__item">
								<router-link :to="{ name: 'AllActivities' }">
									الفعاليات والأنشطة
								</router-link>
							</li>
							<li class="nav-list__item">
								<router-link :to="{ name: 'AllNews' }">
									الأخبار
								</router-link>
							</li>
							<li class="nav-list__item">
								<router-link :to="{ name: 'ContactsView' }">
									تواصل معنا
								</router-link>
							</li>
						</ul>
					</nav>
					<div
						class="drawer-toggle d-block d-md-none"
						@click="showNav = !showNav"
						ref="navToggle">
						<div class="nav-icon white-text touch fs-3"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'MainNavbar',

	data() {
		return {
			showNav: false,

			loading: true,
		};
	},

	computed: {
		...mapState({
			contacts: (state) => state.contacts.contacts[0],
		}),
	},

	mounted() {
		this.$store.dispatch('contacts/fetchAll').then(() => {
			this.loading = false;
		});

		window.addEventListener('click', (e) => {
			if (!this.$refs.navToggle.contains(e.target)) this.showNav = false;
		});
	},
};
</script>

<style lang="scss" scoped>
a:hover {
	color: #ccc;
}
.main-nav {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 2;
}
.top-nav * {
	font-size: 0.8rem;
}

.bottom-nav {
	background: linear-gradient(to bottom, #0007 55%, #0000);
	&.pages {
		background: #0008;
	}
	.brand {
		width: 6rem;
	}

	.nav-list {
		color: white;
		&__item {
			cursor: pointer;
		}
		&__item:not(:last-child) {
			margin-left: 1.6rem;
			@media (max-width: 992px) {
				margin-left: 1rem;
			}
		}
	}

	@media (max-width: 768px) {
		position: relative;
		.nav-links {
			position: absolute;
			right: 15px;
			left: 15px;
			top: 100%;
			background-color: white;
			padding: 1rem 2rem;
			border-radius: 20px;
			box-shadow: 0 0 15px #0003;
			.nav-list {
				color: black;
				flex-direction: column;
				align-items: center;
				&__item {
					margin: 0;
					padding: 10px 0;
				}
			}

			transition: 0.5s;
			transform: translateX(150%);
			&.show {
				transform: translateX(0);
			}
		}
	}
}
</style>
