import * as types from '../mutations';

export default {
    namespaced: true,

    state: {
        conductCode: [],
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/company-history').then((res) => {
                commit(types.CONDUCT_CODE_STORE, res.data);
                return res.data;
            });
        },
    },
    mutations: {
        [types.CONDUCT_CODE_STORE](state, conductCode) {
            state.conductCode = conductCode;
        },
    },
};
