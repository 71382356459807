<template>
	<router-link :to="{ name: 'NewsView', params: { slug: news.slug } }">
		<div
			class="news-card shadow radius-md"
			:title="news.title">
			<!-- card image -->
			<div class="news-card__image">
				<div class="image image--cover">
					<img
						:src="news.primaryImage.url"
						alt="" />
				</div>
			</div>

			<!-- card info -->
			<div class="news-card__text">
				<!-- date -->
				<div class="news-card__text-date">
					{{ DateFormater(news.date) }}
				</div>

				<!-- title -->
				<div class="news-card__text-title">
					{{ news.title }}
				</div>

				<!-- summary -->
				<hr v-if="showSummary" />
				<div
					class="news-card__text-summary"
					v-if="showSummary">
					{{ news.summary }}
				</div>

				<!-- read more -->
				<div class="news-card__text-read-more read-more main-text">
					قراءة المزيد
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
import { DateFormater } from '@/helpers/helpers';
export default {
	name: 'NewsCard',
	props: {
		news: {
			type: Object,
		},
	},
	data() {
		return {
			showSummary: true,
		};
	},
	methods: {
		DateFormater,
	},
};
</script>

<style lang="scss" scoped>
@import '@/style/style.scss';
.news-card:hover {
	transition: 0.3s;
	box-shadow: 0 0 20px #0006 !important;
}
%card-style {
	@media (max-width: 768px) {
		max-width: 350px;
	}
}
%title-style {
	font-weight: bold;

	font-size: 1rem;
	line-height: 1.4em;
	height: 1.4em;
	overflow: hidden;
}
%summary-style {
	opacity: 0.9;
	margin-bottom: 0.5rem;

	font-size: 0.85rem;
	line-height: 1.4em;
	height: 4.2em;
	overflow: hidden;
}
%date-style {
	opacity: 0.5;
	margin-bottom: 0.5rem;

	font-size: 0.9rem;
	margin-bottom: 0.2rem;
}
%read-more-style {
	font-weight: 0.9rem;
	font-weight: 600;
}
%hr-style {
	font-weight: 500;
}
$read-more-transition: 0.3s;
%read-more-hover-style {
	text-decoration: underline;
}

/****************/
.news-card {
	@extend %card-style;
	cursor: pointer;
	margin: auto;
	&__image {
		aspect-ratio: 1 / 1;
		.image {
			height: 100%;
		}
	}
	&__text {
		padding: 25px;
		@media (max-width: 992px) {
			padding: 15px;
		}
		display: flex;
		flex-direction: column;
		&-date {
			@extend %date-style;
		}
		&-title {
			@extend %title-style;
		}
		&-summary {
			@extend %summary-style;
		}
		&-read-more {
			@extend %read-more-style;

			transition: $read-more-transition;
			&:hover {
				@extend %read-more-hover-style;
			}
			margin-top: auto;
		}
		hr {
			@extend %hr-style;

			margin: 3px 0;
		}
	}
}
</style>
