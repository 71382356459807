import HomeView from "../views/HomeView.vue"
import ActivityView from "../views/Activities/ActivityView.vue"
import AllActivities from "../views/Activities/AllActivities.vue"
import AllAlbums from "../views/Albums/AllAlbums.vue"
import AlbumView from "../views/Albums/AlbumView.vue"
import ContactsView from "../views/ContactsView.vue"
import AboutUs from "../views/AboutUs.vue"
import AllNews from "../views/News/AllNews.vue"
import NewsView from "../views/News/NewsView.vue"
import ConductCode from "../views/ConductCodeView.vue"
import AllStudents from "../views/Students/AllStudents.vue"


export default [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/all-activities',
        name: 'AllActivities',
        component: AllActivities
    },
    {
        path: '/activity/:slug',
        name: 'ActivityView',
        component: ActivityView
    },
    {
        path: '/all-albums',
        name: 'AllAlbums',
        component: AllAlbums
    },
    {
        path: '/album/:id',
        name: 'AlbumView',
        component: AlbumView
    },
    {
        path: '/contact-us',
        name: 'ContactsView',
        component: ContactsView
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/all-news',
        name: 'AllNews',
        component: AllNews
    },
    {
        path: '/news/:slug',
        name: 'NewsView',
        component: NewsView
    },
    {
        path: '/conduct-code',
        name: 'ConductCode',
        component: ConductCode
    },
    {
        path: '/students',
        name: 'AllStudents',
        component: AllStudents
    },

]
