<template>
	<router-link :to="{ name: 'AlbumView', params: { id: album.id } }">
		<div class="album-card shadow radius-md">
			<!-- image -->
			<div class="image image--cover">
				<img
					:src="album.primaryImage.url"
					alt="" />
			</div>

			<!-- sec over layer -->
			<div class="content content--sec"></div>

			<!-- main over layer with content -->
			<div class="content center-item text-center">{{ album.title }}</div>
		</div>
	</router-link>
</template>

<script>
export default {
	name: 'AlbumCard',
	props: {
		album: {
			typeof: Object,
		},
	},
};
</script>

<style lang="scss" scoped>
%content-style {
	background: #0006;
	color: white;
	transition: 0.3s;
	font-size: 1.2rem;
	font-weight: bold;
}
%hover-from {
	top: 100%;
	right: 0;
	left: 0;
	bottom: 0;
}
%hover-to {
	top: 0;
}
%hover-sec-from {
	bottom: 100%;
	right: 0;
	left: 0;
	top: 0;
}
%hover-sec-to {
	bottom: 0;
}
.content--sec {
	display: block;
}

/****************/
.album-card {
	cursor: pointer;
	aspect-ratio: 1 / 1;
	@supports not (aspect-ratio: 1 / 1) {
		height: 300px;
		@media (max-width: 1400px) {
			height: 250px;
		}
		@media (max-width: 1200px) {
			height: 200px;
		}
		@media (max-width: 768px) {
			height: 240px;
		}
		@media (max-width: 576px) {
			height: 45vw;
		}
	}
	position: relative;
	.image {
		width: 100%;
		height: 100%;
	}
	.content {
		@extend %content-style;

		position: absolute;
		overflow: hidden;
		z-index: 1;

		@extend %hover-from;
		&--sec {
			@extend %hover-sec-from;
		}
	}
	&:hover {
		.content {
			@extend %hover-to;
			@extend %hover-sec-to;
		}
	}
	@media (max-width: 768px) {
		.content {
			top: 0 !important;
			right: 0 !important;
			left: 0 !important;
			bottom: 0 !important;
		}
	}
}
</style>
