export const ACTIVITIES_STORE = "ACTIVITIES_STORE"

export const CATEGORIES_STORE = "CATEGORIES_STORE"

export const CONTACTS_STORE = "CONTACTS_STORE"

export const SLIDERS_STORE = "SLIDERS_STORE"

export const ABOUT_STORE = "ABOUT_STORE"
export const VALUES_STORE = "VALUES_STORE"
export const SYSTEM_STORE = "SYSTEM_STORE"

export const STUDENTS_STORE = "STUDENTS_STORE"

export const TEACHERS_STORE = "TEACHERS_STORE"

export const NEWS_STORE = "NEWS_STORE"

export const KEYWORDS_STORE = "KEYWORDS_STORE"

export const CONDUCT_CODE_STORE = "CONDUCT_CODE_STORE"

export const ALBUMS_STORE = "ALBUMS_STORE"