<template>
	<!-- loader -->
	<div
		class="loader"
		v-if="loading">
		<loader></loader>
	</div>

	<!-- page -->
	<div
		v-else
		class="page">
		<div class="container">
			<div class="row gy-3">
				<div
					class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
					v-for="newsIndex in news"
					:key="newsIndex.id">
					<news-card :news="newsIndex"></news-card>
				</div>
			</div>
			<div
				class="h3 fw-light text-center py-5"
				v-if="!news.length">
				لا توجد أخبار
			</div>
		</div>
	</div>
</template>

<script>
import NewsCard from '@/components/NewsCard.vue';
import Loader from '@/components/Loader.vue';
import { mapState } from 'vuex';
export default {
	name: 'AllNews',
	components: {
		NewsCard,
		Loader,
	},
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			news: (state) => state.news.news,
		}),
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store.dispatch('news/fetchAllOrGet').then(() => {
				this.loading = false;
			});
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>
