var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('div',{staticClass:"loader"},[_c('loader')],1):_c('div',{staticClass:"page"},[_c('div',{staticClass:"container"},[_c('vue-easy-lightbox',{attrs:{"moveDisabled":"","visible":_vm.visible,"imgs":_vm.activity.images.map((e) => e.url),"index":_vm.index},on:{"hide":_vm.handleHide}}),_c('section',{staticClass:"main-content row mb-3"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-7"},[_c('h1',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.activity.name))]),_c('p',{staticClass:"date mb-2 text-muted"},[_vm._v(" "+_vm._s(_vm.DateFormater(_vm.activity.updatedAt))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.activity.description)}})]),_c('div',{staticClass:"col-12 col-md-6 col-lg-5"},[_c('div',{staticClass:"main-image image image--cover my-3"},[_c('img',{attrs:{"src":_vm.activity.primaryImage.url,"alt":_vm.activity.title}})])])]),_c('hr',{staticClass:"w-50"}),_c('section',{staticClass:"keywords mb-4"},_vm._l((_vm.activity.keywordsIds),function(key,i){return _c('div',{key:i,staticClass:"keywords__item"},[_vm._v(" "+_vm._s(_vm.keywordsGetter(key).name)+" ")])}),0),(_vm.activity.images.length)?_c('section',{staticClass:"more-images mb-5"},[_c('h3',{staticClass:"h5"},[_vm._v("المزيد من الصور")]),_c('div',{staticClass:"more-images__contant"},_vm._l((_vm.activity.images),function(image,i){return _c('div',{key:image.id,staticClass:"more-images__item image image--cover",on:{"click":function($event){_vm.index = i;
						_vm.visible = true;}}},[_c('img',{attrs:{"src":image.url,"alt":`${_vm.activity.title}-image-${i}`}})])}),0)]):_vm._e(),_c('hr'),(
				_vm.activities.filter((e) => e.slug !== _vm.$route.params.slug)
					.length
			)?_c('section',[_c('h2',[_vm._v("المزيد من الفعاليات")]),_c('div',{staticClass:"row gy-3"},_vm._l((_vm.activities
						.filter((e) => e.slug !== _vm.$route.params.slug)
						.slice(0, 4)),function(activityIndex){return _c('div',{key:activityIndex.id,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"},[_c('activity-card',{attrs:{"activity":activityIndex}})],1)}),0)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }