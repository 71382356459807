import Vue from 'vue'
import Vuex from 'vuex'
import feedback from './modules/feedback'
import news from './modules/news'
import albums from './modules/albums'
import activities from './modules/activities'
import categories from './modules/categories'
import contacts from './modules/contacts'
import sliders from './modules/sliders'
import aboutus from './modules/aboutus'
import students from './modules/students'
import teachers from './modules/teachers'
import conductCode from './modules/conductCode'
import keywords from './modules/keywords'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    news,
    albums,
    feedback,
    activities,
    categories,
    contacts,
    sliders,
    aboutus,
    students,
    teachers,
    conductCode,
    keywords
  }
})
