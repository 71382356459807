<template>
	<!-- loader -->
	<div
		class="loader"
		v-if="loading">
		<loader></loader>
	</div>

	<div v-else>
		<!-- header -->
		<header>
			<!-- header slider -->
			<div class="header-content">
				<div class="container position-relative">
					<hooper
						group="header"
						:playSpeed="3000"
						autoPlay
						infiniteScroll
						:wheelControl="false"
						rtl
						class="header-slider">
						<slide
							v-for="(slide, i) in sliders"
							:key="i">
							<div class="header-slider__item">
								{{ slide.description }}
							</div>
						</slide>
						<hooper-pagination
							slot="hooper-addons"></hooper-pagination>
					</hooper>

					<!-- header button -->
					<router-link
						:to="{ name: 'AboutUs' }"
						class="header-button main-bg white-text">
						عن المدرسة
					</router-link>
				</div>
			</div>

			<!-- header images slider -->
			<div class="header-images fill-width fill-height">
				<hooper
					group="header"
					:playSpeed="3000"
					infiniteScroll
					:wheelControl="false"
					rtl
					class="header-images-slider">
					<slide
						v-for="(slide, i) in sliders"
						:key="i">
						<div
							class="header-images-slider__item"
							:style="`background-image: url(${slide.image})`"></div>
					</slide>
				</hooper>
			</div>
		</header>

		<main>
			<!-- activities section -->
			<section class="activities-section">
				<div class="container">
					<!-- title -->
					<div class="text-center mb-4">
						<h2 class="title">فعاليات وانشطة</h2>
						<div class="h6">
							تؤمن مدرسة العودة بان التعليم أساس بناء أجيال واعدة
						</div>
					</div>

					<!-- filter -->
					<div class="activities-filter-container mb-5">
						<div class="activities-filter">
							<div
								class="activities-filter__item"
								:class="{ active: selectedCategory === null }"
								@click="selectedCategory = null">
								الكل
							</div>
							<div
								:class="{
									active: selectedCategory === category.id,
								}"
								@click="selectedCategory = category.id"
								v-for="category in categories"
								class="activities-filter__item"
								:key="category.id">
								{{ category.name }}
							</div>
						</div>
					</div>

					<!-- activities grid -->
					<div
						class="activities-grid"
						v-if="filteredActivities.length">
						<!-- first(big) card for big screen -->
						<div
							class="small-activities__item big-activities shadow radius-md d-none d-md-block">
							<router-link
								:to="{
									name: 'ActivityView',
									params: {
										slug: filteredActivities[0].slug,
									},
								}">
								<div
									class="big-activities__image image image--cover">
									<img
										:src="
											filteredActivities[0].primaryImage
												.url
										"
										alt="" />
								</div>
								<div class="big-activities__text white-bg">
									<div class="big-activities__date">
										{{
											DateFormater(
												filteredActivities[0].updatedAt,
											)
										}}
									</div>
									<div class="big-activities__title">
										{{ filteredActivities[0].name }}
									</div>
									<div class="big-activities__summary">
										{{ filteredActivities[0].summary }}
									</div>
									<div
										class="big-activities__read-more read-more main-text">
										قراءة المزيد
									</div>
								</div>
							</router-link>
						</div>
						<!-- first(big) card for small screen -->
						<div class="small-activities__item d-block d-md-none">
							<activity-card
								:activity="
									filteredActivities[0]
								"></activity-card>
						</div>
						<div
							class="small-activities__item"
							v-for="(activity, i) in filteredActivities.slice(
								1,
								5,
							)"
							:key="i"
							:class="{ 'd-none d-lg-block': i > 1 }">
							<activity-card :activity="activity"></activity-card>
						</div>
					</div>

					<!-- for no data -->
					<div
						v-else
						class="text-center text-muted h4 fw-light py-5">
						لا توجد فعاليات بعد
					</div>
				</div>
				<div class="activities-section-image">
					<div class="image">
						<img
							src="../assets/Logo.png"
							alt="" />
					</div>
				</div>
			</section>

			<!-- students section -->
			<section class="students-section pb-5">
				<div class="container">
					<div class="row gy-4">
						<!-- title -->
						<div class="col-12 col-lg-4 text-center text-lg-end">
							<div class="title mb-3">
								طلابنا أملنا جيل المستقبل الواعد
							</div>
							<div class="main-text h4 fw-normal">
								للعام الدارسي
							</div>
							<div
								ref="DropdownToggler"
								class="student-select dropdown-icon down main-text h2"
								:class="openDropdown ? 'up' : 'down'"
								@click="openDropdown = !openDropdown">
								<span class="select-label cp">
									{{ selectedYear }}
								</span>
								<div
									class="type-dropdown"
									v-if="openDropdown">
									<ul>
										<li
											v-for="(year, i) in years"
											@click="selectedYear = year"
											:key="i">
											{{ year }}
										</li>
									</ul>
								</div>
							</div>
							<router-link
								class="student-button main-bg white-text d-block mx-auto mx-lg-0 mt-3"
								:to="{
									name: 'AllStudents',
									query: { year: selectedYear },
								}">
								رؤية المزيد
							</router-link>
						</div>

						<!-- slider -->
						<div class="col-12 col-lg-8">
							<div>
								<hooper
									v-if="
										students.filter(
											(e) =>
												new Date(
													e.date,
												).getFullYear() ===
												selectedYear,
										).length > 3
									"
									ref="hooper"
									:settings="hooperSettings"
									class="students-slider">
									<slide
										v-for="(student, i) in students.filter(
											(e) =>
												new Date(
													e.date,
												).getFullYear() ===
												selectedYear,
										)"
										:key="i">
										<div
											class="students-slider__item"
											@click="selectedStudent = student">
											<student-card
												:student="
													student
												"></student-card>
										</div>
									</slide>

									<hooper-navigation
										slot="hooper-addons"></hooper-navigation>
								</hooper>
								<div
									v-else
									class="row justify-content-center gy-3">
									<div
										@click="selectedStudent = student"
										class="col-6 col-sm-3"
										v-for="(student, i) in students.slice(
											0,
											4,
										)"
										:key="i">
										<student-card
											:student="student"></student-card>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<!-- teachers section -->
			<section
				class="teachers-section"
				v-if="teachers.length">
				<div class="container">
					<div class="title text-center mb-4">شخصيات مميزة</div>
					<div class="row gy-3">
						<div
							class="col-12 col-sm-6 col-lg-3"
							v-for="(teacher, i) in teachers.slice(0, 4)"
							:key="i">
							<teacher-card :teacher="teacher"></teacher-card>
						</div>
					</div>
				</div>
			</section>

			<!-- news section -->
			<section
				class="news-section"
				v-if="news.length">
				<div class="container">
					<div class="title text-center mb-4">الأخبار</div>
					<div class="row gy-3">
						<div
							class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
							:class="{ 'd-block d-lg-none d-xl-block': i === 3 }"
							v-for="(newsIndex, i) in news.slice(0, 4)"
							:key="i">
							<news-card :news="newsIndex"></news-card>
						</div>
					</div>
				</div>
			</section>
		</main>

		<!-- student dialog -->
		<div
			class="dialog"
			v-if="selectedStudent"
			@click.self="selectedStudent = null">
			<div class="dialog__student">
				<div class="image mb-3">
					<img
						:src="selectedStudent.primaryImage.url"
						alt="" />
				</div>
				<div class="h5 main-text">{{ selectedStudent.title }}</div>
				<div class="h5 fw-bold">{{ selectedStudent.code }}</div>
				<div class="h5 mb-4">{{ selectedStudent.summary }}</div>
				<div
					v-html="selectedStudent.content"
					class="dialog__student-content"></div>
			</div>
		</div>
	</div>
</template>

<script>
import ActivityCard from '@/components/ActivityCard.vue';
import StudentCard from '@/components/StudentCard.vue';
import Loader from '@/components/Loader.vue';
import TeacherCard from '@/components/TeacherCard.vue';
import NewsCard from '@/components/NewsCard.vue';

import {
	Hooper,
	Slide,
	Navigation as HooperNavigation,
	Pagination as HooperPagination,
} from 'hooper';
import 'hooper/dist/hooper.css';
import { mapState } from 'vuex';
import { DateFormater } from '@/helpers/helpers';

export default {
	name: 'HomeView',

	components: {
		ActivityCard,
		StudentCard,
		TeacherCard,
		Loader,
		NewsCard,
		Hooper,
		Slide,
		HooperNavigation,
		HooperPagination,
	},

	data() {
		return {
			loading: true,

			selectedCategory: null,

			openDropdown: false,

			years: [],
			selectedYear: 2023,

			selectedStudent: null,

			hooperSettings: {
				rtl: true,
				vertical: false,
				wheelControl: false,
				hoverPause: true,
				infiniteScroll: true,
				autoPlay: true,
				itemsToShow: 3,
				breakpoints: {
					768: {
						itemsToShow: 5,
					},
				},
			},
		};
	},
	computed: {
		filteredActivities() {
			return this.selectedCategory
				? this.activities.filter((e) =>
						e.categoriesIds.includes(this.selectedCategory),
				  )
				: this.activities;
		},

		...mapState({
			categories: (state) => state.categories.categories,
			activities: (state) => state.activities.activities,
			sliders: (state) => state.sliders.sliders,
			students: (state) => state.students.students,
			teachers: (state) => state.teachers.teachers,
			news: (state) => state.news.news,
		}),
	},
	methods: {
		DateFormater,
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('categories/fetchAll'),
				this.$store.dispatch('activities/fetchAll'),
				this.$store.dispatch('sliders/fetchAll'),
				this.$store.dispatch('students/fetchAll').then((res) => {
					const years = new Set();
					res.forEach((e) => {
						years.add(new Date(e.date).getFullYear());
					});
					this.years = Array.from(years);
				}),
				this.$store.dispatch('teachers/fetchAll'),
				this.$store.dispatch('news/fetchAllOrGet'),
				this.$store.dispatch('keywords/fetchAllOrGet'),
			]).then(() => {
				this.loading = false;
			});
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
@import '../style/style.scss';
main > section {
	padding: 5rem 0 4rem;
}
.title {
	color: $main-color;
	font-size: 2rem;
	font-weight: bold;
	@media (max-width: 576px) {
		font-size: 1.5rem;
	}
}

/* header */
header {
	position: relative;
	z-index: 1;
}
.header-content {
	position: relative;
	z-index: 1;

	padding: 13rem 0 10rem;
	background-image: url('../assets/header-shape.png');
	background-size: 600px 100%;
	@media (min-width: 1500px) {
		background-size: 45% 100%;
	}
	background-position: right center;
	@media (max-width: 768px) {
		background-image: none;
		color: white;
	}

	.header-slider {
		width: 380px;
		@media (max-width: 768px) {
			width: 100%;
		}
		&__item {
			height: 6rem;
			overflow: hidden;
			font-size: 2rem;
			line-height: 3rem;
		}
	}
}
.header-images {
	background-color: #0008;
	&-slider {
		z-index: -1;
		height: 100%;
		&__item {
			background-position: center;
			background-size: cover;
			height: 100%;
		}
	}
}
.header-button {
	position: absolute;
	bottom: -3rem;
	right: 5px;
	padding: 0.3em 1em;
	border-radius: 3rem;
	font-size: 0.9rem;
}

/* activities section */
.activities-section {
	position: relative;
	overflow: hidden;
}
.activities-section-image {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(-50%, -40%);
	z-index: -1;
	width: 40rem;
	.image {
		width: 100%;
		opacity: 0.2;
	}
}
.activities-filter-container {
	max-width: 100%;
	overflow-x: auto;
	padding-bottom: 10px;
}
.activities-filter {
	display: flex;
	align-items: center;
	justify-content: center;
	width: max-content;
	margin: auto;
	&__item {
		padding: 0.3em 1em;
		border: 1px solid black;
		border-radius: 3rem;
		margin: 0 3px;
		min-width: max-content;
		transition: 0.2s;
		cursor: pointer;
		&:hover {
			background-color: #eee;
		}
		&.active {
			background-color: $main-color;
			border-color: $main-color;
			color: white;
		}
	}
}
.activities-grid {
	display: grid;
	grid-template-columns: 5fr 2fr 2fr;
	gap: 1rem;
	aspect-ratio: 9 / 5;
	@media (max-width: 992px) {
		grid-template-columns: 4fr 2fr;
	}
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
	.big-activities {
		grid-row-start: 1;
		grid-row-end: 3;

		&:hover {
			transition: 0.3s;
			box-shadow: 0 0 20px #0006 !important;
		}
		&__image {
			height: calc(100% - 180px);
		}

		&__text {
			padding: 25px;
			@media (max-width: 992px) {
				padding: 15px;
			}
		}
		&__date {
			opacity: 0.5;
			margin-bottom: 0.5rem;

			font-size: 0.9rem;
			margin-bottom: 0.2rem;
		}
		&__title {
			font-weight: bold;

			font-size: 1rem;
			line-height: 1.4em;
		}
		&__summary {
			opacity: 0.9;
			margin-bottom: 0.5rem;

			font-size: 0.85rem;
			line-height: 1.4em;
		}
		&__read-more {
			font-weight: 0.9rem;
			font-weight: 600;
			transition: 0.3s;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

/* student section */
.student-select {
	position: relative;
	&:hover {
		background-color: #0001;
		border-radius: 10px;
	}
	width: max-content;
	@media (max-width: 992px) {
		margin: auto;
	}
	.type-dropdown {
		position: absolute;
		z-index: 2;
		right: 0;
		top: 110%;
		background: white;
		box-shadow: 0 0 20px #0003;
		border-radius: 10px;
		padding: 20px;
		max-height: 200px;
		overflow: auto;
		animation: typeDropdownInter 0.5s forwards;
		@keyframes typeDropdownInter {
			from {
				transform: translateY(-20px);
				opacity: 0;
			}
			to {
				transform: translateY(0);
				opacity: 1;
			}
		}
		> ul {
			list-style: none;
			> li {
				padding: 8px 0;
				cursor: pointer;
				&:hover {
					transform: scale(1.05);
				}
			}
		}
	}
}
.students-section {
	background-image: linear-gradient(#c8c7c7e4, #c8c7c7e4),
		url('../assets/header-bg.jpg');
	background-size: cover;
	background-position: center;
}
.student-button {
	padding: 0.3em 1em;
	border-radius: 3rem;
	font-size: 0.9rem;
	width: max-content;
}

::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	background: #888;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
}
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
* {
	scrollbar-width: thin;
}
</style>
<style lang="scss">
.hooper {
	height: auto;
}
.hooper-prev {
	right: -20px !important;
	padding: 0;
}
.hooper-next {
	left: -20px !important;
	padding: 0;
}
@media (max-width: 768px) {
	.hooper-prev {
		right: -10px !important;
	}
	.hooper-next {
		left: -10px !important;
	}
}
.students-slider {
	height: 320px;
	@media (max-width: 400px) {
		height: 260px;
	}
	.hooper-list {
		padding: 20px 0;
	}
	li {
		transition: 0.3s ease-out;
	}
	&__item {
		padding: 0 0.5rem;
		.image {
			border-radius: 50%;
			overflow: hidden;
		}
	}

	@media (min-width: 768px) {
		li.is-current + li + li {
			transform: scale(1.15);
		}
	}
	@media (max-width: 768px) {
		li.is-current + li {
			transform: scale(1.15);
		}
	}
}
.hooper-pagination {
	bottom: -8rem;
	right: 0;
	transform: none;
	.hooper-indicator {
		width: 10px;
		height: 10px;
		border: 1px solid #888;
		transition: 0.5s;
		&:hover {
			background-color: #aaa;
		}
		&.is-active {
			border: 4px solid #888;
			background-color: transparent;
		}
	}
}
</style>
