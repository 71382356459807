<template>
	<!-- loader -->
	<div class="loader" v-if="loading">loading...</div>

	<!-- page -->
	<div class="container" v-else>
		<div class="row gy-3">
			<div
				class="col-6 col-md-4 col-lg-3"
				v-for="album in albums"
				:key="album.id"
			>
				<album-card :album="album"></album-card>
			</div>
		</div>
	</div>
</template>

<script>
import AlbumCard from "@/components/AlbumCard.vue";
import { mapState } from "vuex";
export default {
	name: "AllAlbums",
	components: { AlbumCard },
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			albums: (state) => state.albums.albums,
		}),
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store.dispatch("albums/fetchAllOrGet").then(() => {
				this.loading = false;
			});
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>