<template>
	<loader v-if="loading"></loader>
	<div
		class="page"
		v-else>
		<div class="container">
			<h1 class="text-center h2 mb-3 main-text">طلابنا المميزون</h1>
			<!-- filter -->
			<div class="activities-filter-container mb-5">
				<div class="activities-filter">
					<div
						:class="{
							active: selectedYear === year,
						}"
						@click="selectedYear = year"
						v-for="(year, i) in years"
						class="activities-filter__item"
						:key="i">
						{{ year }}
					</div>
				</div>
			</div>

			<div class="row">
				<div
					class="student-card col-12 col-sm-6 col-lg-4"
					v-for="student in students.filter(
						(e) => new Date(e.date).getFullYear() === selectedYear,
					)"
					:key="student.id">
					<div
						class="row"
						@click="selectedStudent = student">
						<div class="col-5">
							<div class="image">
								<img
									:src="student.primaryImage.url"
									alt="" />
							</div>
						</div>
						<div class="col-6">
							<div>{{ student.title }}</div>
							<div class="fw-bold">{{ student.code }}</div>
							<div>{{ student.summary }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="dialog"
			v-if="selectedStudent"
			@click.self="selectedStudent = null">
			<div class="dialog__student">
				<div class="image mb-3">
					<img
						:src="selectedStudent.primaryImage.url"
						alt="" />
				</div>
				<div class="h5 main-text">{{ selectedStudent.title }}</div>
				<div class="h5 fw-bold">{{ selectedStudent.code }}</div>
				<div class="h5 mb-4">{{ selectedStudent.summary }}</div>
				<div
					v-html="selectedStudent.content"
					class="dialog__student-content"></div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import { mapState } from 'vuex';

export default {
	name: 'AllStudents',

	components: { Loader },

	data() {
		return {
			loading: true,

			years: [],
			selectedYear: null,

			selectedStudent: null,
		};
	},

	computed: {
		...mapState({
			students: (state) => state.students.students,
		}),
	},

	methods: {
		fetch() {
			this.loading = true;
			this.$store
				.dispatch('students/fetchAll')
				.then((res) => {
					const years = new Set();
					res.forEach((e) => {
						years.add(new Date(e.date).getFullYear());
					});
					this.years = Array.from(years);

					if (this.$route.query.year) {
						this.selectedYear = Number(this.$route.query.year);
					} else {
						this.selectedYear = this.years[0];
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
@import '@/style/style.scss';

.student-card {
	border-right: 5px solid $main-color;
	padding: 10px;
	margin-top: 1rem;

	.image {
		border-radius: 50%;
		overflow: hidden;
	}
}

.activities-filter-container {
	max-width: 100%;
	overflow-x: auto;
	padding-bottom: 10px;
}
.activities-filter {
	display: flex;
	align-items: center;
	justify-content: center;
	width: max-content;
	margin: auto;
	&__item {
		padding: 0.3em 1em;
		border: 1px solid black;
		border-radius: 3rem;
		margin: 0 3px;
		min-width: max-content;
		transition: 0.2s;
		cursor: pointer;
		&:hover {
			background-color: #eee;
		}
		&.active {
			background-color: $main-color;
			border-color: $main-color;
			color: white;
		}
	}
}
</style>
