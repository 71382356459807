<template>
	<!-- loader -->
	<div
		class="loader"
		v-if="loading">
		loading...
	</div>

	<!-- page -->
	<div v-else>
		<!-- lightbox -->
		<vue-easy-lightbox
			moveDisabled
			:visible="visible"
			:imgs="album.images.map((e) => e.url)"
			:index="index"
			@hide="handleHide"></vue-easy-lightbox>

		<!-- main -->
		<div class="container">
			<div class="row gy-3">
				<div
					class="col-6 col-md-4 col-lg-3"
					v-for="(img, i) in album.images"
					:key="img.id"
					@click="
						index = i;
						visible = true;
					">
					<div
						class="album-image image image--cover shadow radius-md cp">
						<img
							:src="img.url"
							:alt="`${album.title}-image-${i}`" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AlbumView',
	data() {
		return {
			album: {},

			loading: true,
			visible: false,
			index: 0,
		};
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store
				.dispatch('albums/fetchByStateOrId', {
					id: this.$route.params.id,
				})
				.then((res) => {
					this.album = res;
					this.loading = false;
				});
		},
		handleHide() {
			this.visible = false;
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
.album-image {
	aspect-ratio: 1 / 1;
	@supports not (aspect-ratio: 1 / 1) {
		height: 300px;
		@media (max-width: 1400px) {
			height: 250px;
		}
		@media (max-width: 1200px) {
			height: 200px;
		}
		@media (max-width: 768px) {
			height: 240px;
		}
		@media (max-width: 576px) {
			height: 45vw;
		}
	}
}
</style>
