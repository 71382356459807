import * as types from '../mutations';

export default {
    namespaced: true,

    state: {
        contacts: [],
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/contacts').then((res) => {
                commit(types.CONTACTS_STORE, res.data);
                return res.data;
            });
        },
    },
    mutations: {
        [types.CONTACTS_STORE](state, contacts) {
            state.contacts = contacts;
        },
    },
};
