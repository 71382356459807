<template>
	<div class="student-card">
		<div class="image image--cover mb-2">
			<img
				:src="student.primaryImage.url"
				:alt="student.title" />
		</div>
		<div class="info text-center">
			{{ student.title }}
			<br />
			<span class="fw-bold"> {{ student.code }} </span>
			<br />
			{{ student.summary }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'StudentCard',

	props: ['student'],
};
</script>

<style lang="scss" scoped>
.student-card {
	.image {
		aspect-ratio: 1 / 1;
		border-radius: 50%;
		border: 1px solid #aaa;
	}
	.info {
		color: #444;
	}
}
</style>
