<template>
	<div class="teacher-card radius-sm p-2 pb-3">
		<div class="image image-cover radius-sm shadow-sm mb-2">
			<img
				:src="teacher.primaryImage.url"
				:alt="teacher.title" />
		</div>
		<div class="subject">{{ teacher.code }}</div>
		<div class="h6 mb-1">{{ teacher.title }}</div>
		<div class="summary">
			{{ teacher.summary }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'TeacherCard',

	props: ['teacher'],
};
</script>

<style lang="scss">
@import '@/style/style.scss';
.teacher-card {
	background-color: #ebebeb;
	.subject {
		font-size: 0.65rem;
		color: $main-color;
	}
	.summary {
		opacity: 0.9;
		font-size: 0.8rem;
		line-height: 1.4rem;
		height: 2.8rem;
		overflow: hidden;
	}
}
</style>
