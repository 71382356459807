import * as types from "../mutations"

export default {
    namespaced: true,

    status: {
        activities: []
    },

    actions: {
        //fetch all activities
        fetchAll({ commit }) {
            return axios.get('/products').then(res => {
                commit(types.ACTIVITIES_STORE, res.data)
                return res.data
            })
        },

        //return the activities. and if it's empty fetch activities
        fetchAllOrGet({ commit, state }) {
            return state.activities || axios.get('/products').then(res => {
                commit(types.ACTIVITIES_STORE, res.data)
                return res.data
            })
        },

        //fetch one activities by it's slug
        fetchBySlug({ commit }, { slug }) {
            return axios.get(`products/${slug}`).then(res => res.data)
        },

        //get one activities by it's slug, and if it's not found fetch it
        fetchByStateOrSlug({ commit, state }, { slug }) {
            let activities = null;
            if (state.activities) {
                activities = state.activities.find(e => e.slug === slug)
            }
            return activities || axios.get(`/products/${slug}`).then(res => res.data)
        }
    },

    mutations: {
        //fill the state with activities
        [types.ACTIVITIES_STORE](state, activities) {
            state.activities = activities
        }
    },

    getters: {
        //return one activities by it's slug
        getBySlug: state => slug => state.activities.find(e => e.slug === slug)
    }
}