<template>
	<div class="loader-container center-item"><span class="loader"></span></div>
</template>

<script>
export default {
	name: 'Loader',
};
</script>

<style scoped lang="scss">
@import '@/style/style.scss';
.loader-container {
	width: 100%;
	height: 80vh;
	padding-top: 200px;
}
.loader {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: inline-block;
	border-top: 4px solid $main-color;
	border-right: 4px solid transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}
.loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	left: 0;
	top: 0;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border-left: 4px solid $sec-color;
	border-bottom: 4px solid transparent;
	animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
