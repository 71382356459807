<template>
	<router-link
		:to="{ name: 'ActivityView', params: { slug: activity.slug } }">
		<div
			class="activity-card white-bg shadow radius-md"
			:title="activity.title">
			<!-- card image -->
			<div class="activity-card__image">
				<div class="image image--cover">
					<img
						:src="activity.primaryImage.url"
						alt="" />
				</div>
			</div>

			<!-- card info -->
			<div class="activity-card__text">
				<!-- date -->
				<div class="activity-card__text-date">
					{{ DateFormater(activity.updatedAt) }}
				</div>

				<!-- title -->
				<div class="activity-card__text-title">
					{{ activity.name }}
				</div>

				<!-- summary -->
				<!-- <hr v-if="showSummary" /> -->
				<div
					class="activity-card__text-summary"
					v-if="showSummary">
					{{ activity.summary }}
				</div>

				<!-- read more -->
				<div class="activity-card__text-read-more read-more main-text">
					قراءة المزيد
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
import { DateFormater } from '@/helpers/helpers';
export default {
	name: 'ActivityCard',
	props: {
		activity: {
			type: Object,
			default: {
				slug: 'slug',
				date: new Date(),
				summary:
					'لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...',
				title: 'عنوان الخبر',
				primaryImage: {
					url: 'https://images.unsplash.com/photo-1591419478162-a4dd21b7ec0a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
				},
			},
		},
	},
	data() {
		return {
			showSummary: true,
		};
	},
	methods: {
		DateFormater,
	},
};
</script>

<style lang="scss" scoped>
@import '@/style/style.scss';
.activity-card:hover {
	transition: 0.3s;
	box-shadow: 0 0 20px #0006 !important;
}
%card-style {
	@media (max-width: 768px) {
		max-width: 350px;
	}
}
%title-style {
	font-weight: bold;

	font-size: 1rem;
	line-height: 1.4em;
	height: 1.4em;
	overflow: hidden;
}
%summary-style {
	opacity: 0.9;
	margin-bottom: 0.5rem;

	font-size: 0.85rem;
	line-height: 1.4em;
	height: 2.8em;
	overflow: hidden;
}
%date-style {
	opacity: 0.5;
	margin-bottom: 0.5rem;

	font-size: 0.9rem;
	margin-bottom: 0.2rem;
}
%read-more-style {
	font-size: 0.8rem;
	font-weight: 0.9rem;
	font-weight: 600;
}
%hr-style {
	font-weight: 500;
}
$read-more-transition: 0.3s;
%read-more-hover-style {
	text-decoration: underline;
}

/****************/
.activity-card {
	@extend %card-style;
	cursor: pointer;
	margin: auto;
	&__image {
		aspect-ratio: 1 / 1;
		.image {
			height: 100%;
		}
	}
	&__text {
		padding: 25px;
		@media (max-width: 992px) {
			padding: 15px;
		}
		display: flex;
		flex-direction: column;
		&-date {
			@extend %date-style;
		}
		&-title {
			@extend %title-style;
		}
		&-summary {
			@extend %summary-style;
		}
		&-read-more {
			@extend %read-more-style;

			transition: $read-more-transition;
			&:hover {
				@extend %read-more-hover-style;
			}
			margin-top: auto;
		}
		hr {
			@extend %hr-style;

			margin: 3px 0;
		}
	}
}
</style>
