<template>
	<div class="page">
		<div class="container">
			<div class="row gx-3 gx-lg-5 gy-4">
				<div class="col-12 col-md-7 col-lg-8">
					<h2 class="main-text mb-3">أرسل لنا</h2>
					<feedback-form :inputClass="'inputs'">
						<button
							class="form-button main-bg white-text"
							type="submit"
							slot="button">
							إرسال
						</button>
					</feedback-form>
				</div>
				<div class="col-12 col-md-5 col-lg-4">
					<h2 class="main-text mb-3">تواصل معنا</h2>
					<ul v-if="contacts">
						<li
							class="location mb-3"
							v-if="contacts.address">
							<a
								:href="contacts.address"
								target="_blank">
								{{ contacts.address }}
							</a>
						</li>
						<li
							class="phone mb-3"
							v-if="contacts.phone">
							<a
								:href="`tel://${contacts.phone}`"
								target="_blank">
								{{ contacts.phone }}
							</a>
						</li>
						<hr style="width: 50%" />
						<li>
							<div
								class="social h4"
								v-if="contacts">
								<a
									:href="contacts.facebook"
									target="_blank"
									class="facebook"
									v-if="contacts.facebook">
								</a>
								<a
									:href="contacts.twitter"
									target="_blank"
									class="twitter"
									v-if="contacts.twitter">
								</a>
								<a
									:href="contacts.instagram"
									target="_blank"
									class="instagram"
									v-if="contacts.instagram">
								</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FeedbackForm from '@/components/FeedbackForm.vue';
import { mapState } from 'vuex';

export default {
	name: 'ContactsView',

	components: {
		FeedbackForm,
	},
	computed: {
		...mapState({
			contacts: (state) => state.contacts.contacts[0],
		}),
	},
};
</script>

<style lang="scss">
.inputs {
	border: 1px solid #ccc;
	border-radius: 15px;
}
.form-button {
	padding: 0.4em 2em;
	border-radius: 20px;
	width: max-content;
	&:hover {
		box-shadow: 0 0 10px #0005;
	}
}
</style>
<style lang="scss" scoped>
@import '@/style/style.scss';
a:hover {
	color: $main-color;
}
</style>
