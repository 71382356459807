<template>
	<!-- loader -->
	<div
		class="loader"
		v-if="loading">
		<loader></loader>
	</div>

	<!-- page -->
	<div
		v-else
		class="page">
		<div class="container">
			<!-- light box -->
			<vue-easy-lightbox
				moveDisabled
				:visible="visible"
				:imgs="activity.images.map((e) => e.url)"
				:index="index"
				@hide="handleHide"></vue-easy-lightbox>

			<!-- main section -->
			<section class="main-content row mb-3">
				<!-- descritption -->
				<div class="col-12 col-md-6 col-lg-7">
					<h1 class="mb-2">{{ activity.name }}</h1>
					<p class="date mb-2 text-muted">
						{{ DateFormater(activity.updatedAt) }}
					</p>
					<div v-html="activity.description"></div>
				</div>

				<!-- image -->
				<div class="col-12 col-md-6 col-lg-5">
					<div class="main-image image image--cover my-3">
						<img
							:src="activity.primaryImage.url"
							:alt="activity.title" />
					</div>
				</div>
			</section>

			<hr class="w-50" />
			<section class="keywords mb-4">
				<div
					class="keywords__item"
					v-for="(key, i) in activity.keywordsIds"
					:key="i">
					{{ keywordsGetter(key).name }}
				</div>
			</section>

			<!-- more images -->
			<section
				class="more-images mb-5"
				v-if="activity.images.length">
				<h3 class="h5">المزيد من الصور</h3>
				<div class="more-images__contant">
					<div
						class="more-images__item image image--cover"
						v-for="(image, i) in activity.images"
						:key="image.id"
						@click="
							index = i;
							visible = true;
						">
						<img
							:src="image.url"
							:alt="`${activity.title}-image-${i}`" />
					</div>
				</div>
			</section>

			<hr />
			<!-- more activities -->
			<section
				v-if="
					activities.filter((e) => e.slug !== $route.params.slug)
						.length
				">
				<h2>المزيد من الفعاليات</h2>
				<div class="row gy-3">
					<div
						class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
						v-for="activityIndex in activities
							.filter((e) => e.slug !== $route.params.slug)
							.slice(0, 4)"
						:key="activityIndex.id">
						<activity-card
							:activity="activityIndex"></activity-card>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import ActivityCard from '@/components/ActivityCard.vue';
import Loader from '@/components/Loader.vue';
import { DateFormater } from '@/helpers/helpers';
import { mapState, mapGetters } from 'vuex';

export default {
	name: 'ActivityView',
	components: { ActivityCard, Loader },
	data() {
		return {
			activity: {},

			loading: true,
			index: 0,
			visible: false,
		};
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store
				.dispatch('activities/fetchAllOrGet', {
					slug: this.$route.params.slug,
				})
				.then((res) => {
					this.activity = this.activityGetter(
						this.$route.params.slug,
					);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		DateFormater,
		handleHide() {
			this.visible = false;
		},
	},
	computed: {
		...mapGetters({
			activityGetter: 'activities/getBySlug',
			keywordsGetter: 'keywords/getById',
		}),
		...mapState({
			activities: (state) => state.activities.activities,
		}),
	},
	watch: {
		'$route.params.slug'() {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			this.fetch();
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
%main-image {
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 0 5px #0001;
}
h1,
h2 {
	text-transform: capitalize;
}
h1 {
	font-size: 2rem;
	font-weight: bold;
}
h2 {
	font-size: 1.5rem;
	font-weight: bold;
}
.keywords {
	display: flex;
	flex-wrap: wrap;
}
.keywords__item {
	font-size: 0.8rem;
	padding: 0.3em 1em;
	border: 1px solid #ccc;
	border-radius: 5rem;
	margin-left: 0.5rem;
}

/****************/
.main-image {
	@extend %main-image;
}
</style>
