import * as types from "../mutations"

export default {
    namespaced: true,

    status: {
        news: []
    },

    actions: {
        //fetch all news
        fetchAll({ commit }) {
            return axios.get('/news').then(res => {
                commit(types.NEWS_STORE, res.data)
                return res.data
            })
        },

        //return the news. and if it's empty fetch news
        fetchAllOrGet({ commit, state }) {
            return state.news || axios.get('/news').then(res => {
                commit(types.NEWS_STORE, res.data)
                return res.data
            })
        },

        //fetch one news by it's slug
        fetchBySlug({ commit }, { slug }) {
            return axios.get(`news/${slug}`).then(res => res.data)
        },

        //get one news by it's slug, and if it's not found fetch it
        fetchByStateOrSlug({ commit, state }, { slug }) {
            let news = null;
            if (state.news) {
                news = state.news.find(e => e.slug === slug)
            }
            return news || axios.get(`/news/${slug}`).then(res => res.data)
        }
    },

    mutations: {
        //fill the state with news
        [types.NEWS_STORE](state, news) {
            state.news = news
        }
    },

    getters: {
        //return one news by it's slug
        getBySlug: state => slug => state.news.find(e => e.slug === slug)
    }
}