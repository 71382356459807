<template>
	<div class="footer-container alt-bg">
		<div class="container">
			<div class="top-section">
				<div class="page-footer">
					<!-- logo and description -->
					<div class="page-footer__part page-footer__part--main">
						<div class="flex flex-column align-items-center">
							<div class="brand image mb-2">
								<img
									src="@/assets/Logo.png"
									alt="" />
							</div>

							<div
								class="social social--inline justify-content-center"
								v-if="contacts">
								<a
									:href="contacts.facebook"
									target="_blank"
									class="facebook"
									v-if="contacts.facebook">
								</a>
								<a
									:href="contacts.twitter"
									target="_blank"
									class="twitter"
									v-if="contacts.twitter">
								</a>
								<a
									:href="contacts.instagram"
									target="_blank"
									class="instagram"
									v-if="contacts.instagram">
								</a>
							</div>
						</div>
					</div>
					<div
						class="page-footer__part page-footer__part--spacer-1"></div>

					<!-- website links -->
					<div
						class="page-footer__part page-footer__part--page-links">
						<div class="links links--page-links">
							<div class="links-title">روابط هامة</div>
							<ul class="links-list">
								<li class="links-list__item">
									<router-link :to="{ name: 'home' }">
										الرئيسية
									</router-link>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'AboutUs' }">
										عن المدرسة
									</router-link>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'ConductCode' }">
										النظام الداخلي
									</router-link>
								</li>
								<li class="links-list__item">
									<router-link
										:to="{ name: 'AllActivities' }">
										الفعاليات
									</router-link>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'AllStudents' }">
										طلابنا المميزون
									</router-link>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'AllNews' }">
										الأخبار
									</router-link>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'ContactsView' }">
										تواصل معنا
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div
						class="page-footer__part page-footer__part--spacer-2"></div>

					<!-- categories links -->
					<div
						class="page-footer__part page-footer__part--categories">
						<div class="links links--categories">
							<div class="links-title">الفعاليات والأنشطة</div>
							<ul class="links-list">
								<li
									class="links-list__item"
									v-for="category in categories"
									:key="category.id">
									<router-link
										:to="{
											name: 'AllActivities',
											query: { id: category.id },
										}">
										{{ category.name }}
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div
						class="page-footer__part page-footer__part--spacer-3"></div>

					<!-- contact us -->
					<div
						class="page-footer__part page-footer__part--contact-us">
						<div class="links links--contact-us">
							<div class="links-title">تواصل معنا</div>
							<ul class="links-list">
								<li
									class="links-list__item location"
									v-if="contacts && contacts.address">
									<a
										:href="contacts.address"
										target="_blank">
										{{ contacts.address }}
									</a>
								</li>
								<li
									class="links-list__item phone"
									v-if="contacts && contacts.phone">
									<a
										:href="`tel://${contacts.phone}`"
										target="_blank">
										{{ contacts.phone }}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-section">
				<div class="beetronix"></div>
				<div class="copy-rights">
					جميع الحقوق محفوظة&copy;{{ new Date().getFullYear() }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'MainFooter',

	computed: {
		...mapState({
			contacts: (state) => state.contacts.contacts[0],
			categories: (state) => state.categories.categories,
		}),
	},
};
</script>
<style lang="scss" scoped>
@import '../style/style.scss';

.footer-container {
	border-top: 7px solid $main-color;
	padding: 3rem 0 1.5rem;
	* {
		color: white;
		font-size: 0.9rem;
	}
	.brand {
		max-width: 240px;
		margin: auto;
		img {
			filter: brightness(10);
		}
	}
}
</style>
<style lang="scss" scoped>
@use 'sass:list';
@use 'sass:map';

$center-for-mobile: 576px;
$part-margin-x: 10px;
$bottom-section-justify-content: space-between;

%list-title {
	padding: 5px;
	font-weight: bold;
	font-size: 1rem;
	margin-bottom: 25px;
	text-transform: capitalize;
	position: relative;
}
%list-title-after {
	position: absolute;
	top: calc(100% + 5px);
	right: 5px;
	width: 3rem;
	height: 1px;
	background-color: white;
	@media (max-width: 576px) {
		right: 50%;
		transform: translateX(50%);
	}
}

%list-item {
	padding: 5px;
	line-height: 1em;
	margin-bottom: 20px;
}
%list-item-hover {
	color: #666;
	text-decoration: underline;
}

$page-links-col: 2;
$categories-col: 2;
$contact-us-col: 1;

$parts: (
	'main': (
		'order': 1,
		'width': 20%,
		'widths': (
			992px: 20%,
			768px: 100%,
			576px: 100%,
		),
	),
	'page-links': (
		'order': 3,
		'width': 25%,
		'widths': (
			992px: 28%,
			768px: 49%,
			576px: 100%,
		),
	),
	'categories': (
		'order': 5,
		'width': 20%,
		'widths': (
			992px: 23%,
			768px: 49%,
			576px: 100%,
		),
	),
	'contact-us': (
		'order': 7,
		'width': 20%,
		'widths': (
			992px: 23%,
			768px: 50%,
			576px: 100%,
		),
	),
	'spacer-1': (
		'order': 2,
		'width': 5%,
		'widths': (
			992px: 2%,
			768px: 0%,
			576px: 0%,
		),
	),
	'spacer-2': (
		'order': 3,
		'width': 5%,
		'widths': (
			992px: 2%,
			768px: 2%,
			576px: 0%,
		),
	),
	'spacer-3': (
		'order': 5,
		'width': 5%,
		'widths': (
			992px: 2%,
			768px: 0%,
			576px: 0%,
		),
	),
);

@each $part-name, $part-prop in $parts {
	.page-footer__part--#{$part-name} {
		order: map.get($part-prop, 'order');
		width: map.get($part-prop, 'width');
	}
	@each $breakPx, $width in map.get($part-prop, 'widths') {
		@media (max-width: $breakPx) {
			.page-footer__part--#{$part-name} {
				width: $width;
			}
		}
	}
}

/****************/
.page-footer {
	display: flex;
	flex-wrap: wrap;
	@if $center-for-mobile {
		@media (max-width: $center-for-mobile) {
			justify-content: center;
			text-align: center;
		}
	}
	&__part {
		margin: $part-margin-x 0;
		padding: 0 5px;
		&--spacer-1,
		&--spacer-2,
		&--spacer-3 {
			padding: 0;
		}
	}
}
.links {
	&-title {
		@extend %list-title;
		&::after {
			content: '';
			@extend %list-title-after;
		}
	}
	&-list {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		&__item {
			@extend %list-item;
			& > a:hover {
				@extend %list-item-hover;
				cursor: pointer;
			}
		}
	}
}
.links--page-links {
	.links-list__item {
		width: calc(100% / $page-links-col);
	}
}
.links--categories {
	.links-list__item {
		width: calc(100% / $categories-col);
	}
}
.links--contact-us {
	.links-list__item {
		width: calc(100% / $contact-us-col);
	}
}
.bottom-section {
	display: flex;
	align-items: center;
	justify-content: $bottom-section-justify-content;
	* {
		font-size: 0.85rem;
	}
}
</style>
