import * as types from "../mutations"

export default {
    namespaced: true,

    state: {
        students: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/services').then(res => {
                commit(types.STUDENTS_STORE, res.data)
                return res.data
            })
        }
    },

    mutations: {
        [types.STUDENTS_STORE](state, students) {
            state.students = students
        }
    }
}