<template>
	<!-- loader -->
	<div
		class="loader"
		v-if="loading">
		<loader></loader>
	</div>

	<!-- page -->
	<div
		v-else
		class="page">
		<div class="container">
			<!-- filter -->
			<div class="activities-filter-container mb-5">
				<div class="activities-filter">
					<div
						class="activities-filter__item"
						:class="{ active: selectedCategory === null }"
						@click="selectedCategory = null">
						الكل
					</div>
					<div
						:class="{
							active: selectedCategory === category.id,
						}"
						@click="selectedCategory = category.id"
						v-for="category in categories"
						class="activities-filter__item"
						:key="category.id">
						{{ category.name }}
					</div>
				</div>
			</div>

			<!-- activities grid -->
			<div
				class="row gy-3"
				v-if="filteredActivities.length">
				<div
					class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
					v-for="activity in filteredActivities"
					:key="activity.id">
					<activity-card :activity="activity"></activity-card>
				</div>
			</div>

			<!-- for no data -->
			<div
				v-else
				class="text-center text-muted h4 fw-light py-5">
				لا توجد فعاليات بعد
			</div>
		</div>
	</div>
</template>

<script>
import ActivityCard from '@/components/ActivityCard.vue';
import Loader from '@/components/Loader.vue';
import { mapState } from 'vuex';
export default {
	name: 'AllActivities',
	components: {
		ActivityCard,
		Loader,
	},
	data() {
		return {
			loading: true,
			selectedCategory: null,
		};
	},
	computed: {
		filteredActivities() {
			return this.selectedCategory
				? this.activities.filter((e) =>
						e.categoriesIds.includes(this.selectedCategory),
				  )
				: this.activities;
		},

		...mapState({
			categories: (state) => state.categories.categories,
			activities: (state) => state.activities.activities,
		}),
	},
	methods: {
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('activities/fetchAllOrGet'),
				this.$store.dispatch('categories/fetchAll'),
			]).then(() => {
				this.loading = false;
			});
		},
	},

	watch: {
		'$route.query.id'() {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			if (this.$route.query.id)
				this.selectedCategory = Number(this.$route.query.id);
			else {
				this.selectedCategory = null;
			}
		},
	},

	mounted() {
		this.fetch();
		if (this.$route.query.id)
			this.selectedCategory = Number(this.$route.query.id);
	},
};
</script>

<style lang="scss" scoped>
@import '@/style/style.scss';

.activities-filter-container {
	max-width: 100%;
	overflow-x: auto;
	padding-bottom: 10px;
}
.activities-filter {
	display: flex;
	align-items: center;
	justify-content: center;
	width: max-content;
	margin: auto;
	&__item {
		padding: 0.3em 1em;
		border: 1px solid black;
		border-radius: 3rem;
		margin: 0 3px;
		min-width: max-content;
		transition: 0.2s;
		cursor: pointer;
		&:hover {
			background-color: #eee;
		}
		&.active {
			background-color: $main-color;
			border-color: $main-color;
			color: white;
		}
	}
}
</style>
