<template>
	<loader v-if="loading"></loader>
	<div
		class="page"
		v-else>
		<div class="container">
			<h1 class="mb-4 main-text">ثانوية العودة الخاصة</h1>
			<h2
				class="sec-text"
				v-if="about.find((e) => e.key === 'about')?.value">
				عن المدرسة
			</h2>
			<div
				v-html="about.find((e) => e.key === 'about')?.value"
				class="mb-5"></div>
			<h2
				class="sec-text"
				v-if="about.find((e) => e.key === 'vision')?.value">
				رؤية المدرسة
			</h2>
			<div v-html="about.find((e) => e.key === 'vision')?.value"></div>

			<h2
				class="sec-text mb-4"
				v-if="values.length">
				قيم المدرسة
			</h2>
			<div class="row gy-3">
				<div
					class="col-12 col-md-5 justify-content-between"
					v-for="value in values"
					:key="value.id">
					<div class="value-card d-flex">
						<div class="value-card__image">
							<div class="image">
								<img
									:src="value.primaryImage.url"
									alt="" />
							</div>
						</div>
						<div class="value-card__text">
							<div
								class="value-card__text-title main-text fw-bold">
								{{ value.title }}
							</div>
							<div
								class="value-card__text-content"
								v-html="value.content"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import { mapState } from 'vuex';
export default {
	name: 'AboutUs',

	components: {
		Loader,
	},

	data() {
		return {
			loading: true,
		};
	},

	computed: {
		...mapState({
			about: (state) => state.aboutus.about,
			values: (state) => state.aboutus.values,
		}),
	},

	methods: {
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('aboutus/fetchAll'),
				this.$store.dispatch('aboutus/fetchValues'),
			]).then(() => {
				this.loading = false;
			});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>

<style scoped lang="scss">
.value-card {
	&__image {
		aspect-ratio: 1 / 1;
		height: 5rem;
		margin-left: 1rem;
		.image {
			height: 100%;
		}
	}
}
</style>
