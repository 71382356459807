<template>
	<loader v-if="loading"></loader>
	<div
		v-else
		class="page">
		<div class="container">
			<h1 class="main-text">النظام الداخلي للمدرسة</h1>
			<div v-html="conductCode?.content"></div>
		</div>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import { mapState } from 'vuex';
export default {
	name: 'ConductCodeView',
	components: { Loader },
	data() {
		return {
			loading: true,
		};
	},

	computed: {
		...mapState({
			conductCode: (state) => state.conductCode.conductCode[0],
		}),
	},

	methods: {
		fetch() {
			this.loading = true;
			this.$store.dispatch('conductCode/fetchAll').then(() => {
				this.loading = false;
			});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>
