var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('div',{staticClass:"loader"},[_c('loader')],1):_c('div',[_c('header',[_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"container position-relative"},[_c('hooper',{staticClass:"header-slider",attrs:{"group":"header","playSpeed":3000,"autoPlay":"","infiniteScroll":"","wheelControl":false,"rtl":""}},[_vm._l((_vm.sliders),function(slide,i){return _c('slide',{key:i},[_c('div',{staticClass:"header-slider__item"},[_vm._v(" "+_vm._s(slide.description)+" ")])])}),_c('hooper-pagination',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2),_c('router-link',{staticClass:"header-button main-bg white-text",attrs:{"to":{ name: 'AboutUs' }}},[_vm._v(" عن المدرسة ")])],1)]),_c('div',{staticClass:"header-images fill-width fill-height"},[_c('hooper',{staticClass:"header-images-slider",attrs:{"group":"header","playSpeed":3000,"infiniteScroll":"","wheelControl":false,"rtl":""}},_vm._l((_vm.sliders),function(slide,i){return _c('slide',{key:i},[_c('div',{staticClass:"header-images-slider__item",style:(`background-image: url(${slide.image})`)})])}),1)],1)]),_c('main',[_c('section',{staticClass:"activities-section"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"activities-filter-container mb-5"},[_c('div',{staticClass:"activities-filter"},[_c('div',{staticClass:"activities-filter__item",class:{ active: _vm.selectedCategory === null },on:{"click":function($event){_vm.selectedCategory = null}}},[_vm._v(" الكل ")]),_vm._l((_vm.categories),function(category){return _c('div',{key:category.id,staticClass:"activities-filter__item",class:{
								active: _vm.selectedCategory === category.id,
							},on:{"click":function($event){_vm.selectedCategory = category.id}}},[_vm._v(" "+_vm._s(category.name)+" ")])})],2)]),(_vm.filteredActivities.length)?_c('div',{staticClass:"activities-grid"},[_c('div',{staticClass:"small-activities__item big-activities shadow radius-md d-none d-md-block"},[_c('router-link',{attrs:{"to":{
								name: 'ActivityView',
								params: {
									slug: _vm.filteredActivities[0].slug,
								},
							}}},[_c('div',{staticClass:"big-activities__image image image--cover"},[_c('img',{attrs:{"src":_vm.filteredActivities[0].primaryImage
											.url,"alt":""}})]),_c('div',{staticClass:"big-activities__text white-bg"},[_c('div',{staticClass:"big-activities__date"},[_vm._v(" "+_vm._s(_vm.DateFormater( _vm.filteredActivities[0].updatedAt, ))+" ")]),_c('div',{staticClass:"big-activities__title"},[_vm._v(" "+_vm._s(_vm.filteredActivities[0].name)+" ")]),_c('div',{staticClass:"big-activities__summary"},[_vm._v(" "+_vm._s(_vm.filteredActivities[0].summary)+" ")]),_c('div',{staticClass:"big-activities__read-more read-more main-text"},[_vm._v(" قراءة المزيد ")])])])],1),_c('div',{staticClass:"small-activities__item d-block d-md-none"},[_c('activity-card',{attrs:{"activity":_vm.filteredActivities[0]}})],1),_vm._l((_vm.filteredActivities.slice(
							1,
							5,
						)),function(activity,i){return _c('div',{key:i,staticClass:"small-activities__item",class:{ 'd-none d-lg-block': i > 1 }},[_c('activity-card',{attrs:{"activity":activity}})],1)})],2):_c('div',{staticClass:"text-center text-muted h4 fw-light py-5"},[_vm._v(" لا توجد فعاليات بعد ")])]),_vm._m(1)]),_c('section',{staticClass:"students-section pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row gy-4"},[_c('div',{staticClass:"col-12 col-lg-4 text-center text-lg-end"},[_c('div',{staticClass:"title mb-3"},[_vm._v(" طلابنا أملنا جيل المستقبل الواعد ")]),_c('div',{staticClass:"main-text h4 fw-normal"},[_vm._v(" للعام الدارسي ")]),_c('div',{ref:"DropdownToggler",staticClass:"student-select dropdown-icon down main-text h2",class:_vm.openDropdown ? 'up' : 'down',on:{"click":function($event){_vm.openDropdown = !_vm.openDropdown}}},[_c('span',{staticClass:"select-label cp"},[_vm._v(" "+_vm._s(_vm.selectedYear)+" ")]),(_vm.openDropdown)?_c('div',{staticClass:"type-dropdown"},[_c('ul',_vm._l((_vm.years),function(year,i){return _c('li',{key:i,on:{"click":function($event){_vm.selectedYear = year}}},[_vm._v(" "+_vm._s(year)+" ")])}),0)]):_vm._e()]),_c('router-link',{staticClass:"student-button main-bg white-text d-block mx-auto mx-lg-0 mt-3",attrs:{"to":{
								name: 'AllStudents',
								query: { year: _vm.selectedYear },
							}}},[_vm._v(" رؤية المزيد ")])],1),_c('div',{staticClass:"col-12 col-lg-8"},[_c('div',[(
									_vm.students.filter(
										(e) =>
											new Date(
												e.date,
											).getFullYear() ===
											_vm.selectedYear,
									).length > 3
								)?_c('hooper',{ref:"hooper",staticClass:"students-slider",attrs:{"settings":_vm.hooperSettings}},[_vm._l((_vm.students.filter(
										(e) =>
											new Date(
												e.date,
											).getFullYear() ===
											_vm.selectedYear,
									)),function(student,i){return _c('slide',{key:i},[_c('div',{staticClass:"students-slider__item",on:{"click":function($event){_vm.selectedStudent = student}}},[_c('student-card',{attrs:{"student":student}})],1)])}),_c('hooper-navigation',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2):_c('div',{staticClass:"row justify-content-center gy-3"},_vm._l((_vm.students.slice(
										0,
										4,
									)),function(student,i){return _c('div',{key:i,staticClass:"col-6 col-sm-3",on:{"click":function($event){_vm.selectedStudent = student}}},[_c('student-card',{attrs:{"student":student}})],1)}),0)],1)])])])]),(_vm.teachers.length)?_c('section',{staticClass:"teachers-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title text-center mb-4"},[_vm._v("شخصيات مميزة")]),_c('div',{staticClass:"row gy-3"},_vm._l((_vm.teachers.slice(0, 4)),function(teacher,i){return _c('div',{key:i,staticClass:"col-12 col-sm-6 col-lg-3"},[_c('teacher-card',{attrs:{"teacher":teacher}})],1)}),0)])]):_vm._e(),(_vm.news.length)?_c('section',{staticClass:"news-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title text-center mb-4"},[_vm._v("الأخبار")]),_c('div',{staticClass:"row gy-3"},_vm._l((_vm.news.slice(0, 4)),function(newsIndex,i){return _c('div',{key:i,staticClass:"col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3",class:{ 'd-block d-lg-none d-xl-block': i === 3 }},[_c('news-card',{attrs:{"news":newsIndex}})],1)}),0)])]):_vm._e()]),(_vm.selectedStudent)?_c('div',{staticClass:"dialog",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.selectedStudent = null}}},[_c('div',{staticClass:"dialog__student"},[_c('div',{staticClass:"image mb-3"},[_c('img',{attrs:{"src":_vm.selectedStudent.primaryImage.url,"alt":""}})]),_c('div',{staticClass:"h5 main-text"},[_vm._v(_vm._s(_vm.selectedStudent.title))]),_c('div',{staticClass:"h5 fw-bold"},[_vm._v(_vm._s(_vm.selectedStudent.code))]),_c('div',{staticClass:"h5 mb-4"},[_vm._v(_vm._s(_vm.selectedStudent.summary))]),_c('div',{staticClass:"dialog__student-content",domProps:{"innerHTML":_vm._s(_vm.selectedStudent.content)}})])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-4"},[_c('h2',{staticClass:"title"},[_vm._v("فعاليات وانشطة")]),_c('div',{staticClass:"h6"},[_vm._v(" تؤمن مدرسة العودة بان التعليم أساس بناء أجيال واعدة ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activities-section-image"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../assets/Logo.png"),"alt":""}})])])
}]

export { render, staticRenderFns }