<template>
	<div id="app">
		<main-navbar />
		<router-view />
		<main-footer />
	</div>
</template>
<script>
import MainFooter from './components/MainFooter.vue';
import MainNavbar from './components/MainNavbar.vue';
export default {
	components: { MainFooter, MainNavbar },
};
</script>
<style lang="scss">
@import '@/style/helpers.scss';
@import '@/style/components.scss';

.page {
	margin-top: 200px;
	padding-bottom: 50px;
	min-height: calc(100vh - 110px - 320px);
}

.dialog {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #0003;
	box-shadow: 0 0 15px #000a;
	&__student {
		margin-top: 3rem;
		width: 14rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: white;
		border-radius: 10px;
		padding: 2rem;
		text-align: center;
		.image {
			width: 10rem;
			border-radius: 10px;
			overflow: hidden;
		}
		&-dialog {
			max-height: 7.5em;
			overflow-y: auto;
		}
	}
}
</style>
