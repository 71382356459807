<template>
	<!-- loader -->
	<div
		class="loader"
		v-if="loading">
		<loader></loader>
	</div>

	<!-- page -->
	<div
		v-else
		class="page">
		<div class="container">
			<!-- light box -->
			<vue-easy-lightbox
				moveDisabled
				:visible="visible"
				:imgs="newsIndex.images.map((e) => e.url)"
				:index="index"
				@hide="handleHide"></vue-easy-lightbox>

			<!-- main section -->
			<section class="main-content row mb-5">
				<!-- descritption -->
				<div class="col-12 col-md-6 col-lg-7">
					<h1 class="mb-0">{{ newsIndex.title }}</h1>
					<p class="date mb-2 text-muted">
						{{ DateFormater(newsIndex.date) }}
					</p>
					<div v-html="newsIndex.content"></div>
				</div>

				<!-- image -->
				<div class="col-12 col-md-6 col-lg-5">
					<div class="main-image image image--cover my-3">
						<img
							:src="newsIndex.primaryImage.url"
							:alt="newsIndex.title" />
					</div>
				</div>
			</section>

			<!-- more images -->
			<section
				class="more-images mb-5"
				v-if="newsIndex.images.length">
				<h2>المزيد من الصور</h2>
				<div class="more-images__contant">
					<div
						class="more-images__item image image--cover"
						v-for="(image, i) in newsIndex.images"
						:key="image.id"
						@click="
							index = i;
							visible = true;
						">
						<img
							:src="image.url"
							:alt="`${newsIndex.title}-image-${i}`" />
					</div>
				</div>
			</section>
			<hr />
			<!-- more news -->
			<section
				class="more-news"
				v-if="news.filter((e) => e.slug !== $route.params.slug).length">
				<h2>المزيد من الأخبار</h2>
				<div class="row gy-3">
					<div
						class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
						:class="{ 'd-block d-lg-none d-xl-block': i === 3 }"
						v-for="(newsIndex, i) in news

							.filter((e) => e.slug !== $route.params.slug)
							.slice(0, 4)"
						:key="newsIndex.id">
						<news-card :news="newsIndex"></news-card>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import NewsCard from '@/components/NewsCard.vue';
import Loader from '@/components/Loader.vue';
import { DateFormater } from '@/helpers/helpers';
import { mapState, mapGetters } from 'vuex';
export default {
	name: 'NewsView',
	components: { NewsCard, Loader },
	data() {
		return {
			newsIndex: {},

			loading: true,
			index: 0,
			visible: false,
		};
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store
				.dispatch('news/fetchAllOrGet', {
					slug: this.$route.params.slug,
				})
				.then((res) => {
					this.newsIndex = this.newsGetter(this.$route.params.slug);
					this.loading = false;
				});
		},
		DateFormater,
		handleHide() {
			this.visible = false;
		},
	},
	computed: {
		...mapGetters({
			newsGetter: 'news/getBySlug',
		}),
		...mapState({
			news: (state) => state.news.news,
		}),
	},
	watch: {
		'$route.params.slug'() {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			this.fetch();
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
%main-image {
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 0 5px #0001;
}
h1,
h2 {
	text-transform: capitalize;
}
h1 {
	font-size: 2rem;
	font-weight: bold;
}
h2 {
	font-size: 1.5rem;
	font-weight: bold;
}

/****************/
.main-image {
	@extend %main-image;
}
</style>
