import * as types from '../mutations';

export default {
    namespaced: true,

    state: {
        categories: [],
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/categories').then((res) => {
                commit(types.CATEGORIES_STORE, res.data);
                return res.data;
            });
        },
    },

    mutations: {
        [types.CATEGORIES_STORE](state, categories) {
            state.categories = categories;
        },
    },
};
